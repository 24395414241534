import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const Education = ({data}) => {
  return (
    <>
      <Seo title="Education" description="The Education (ED) cluster provides a full view of educational institutions in the state."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="30%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Education</h2>
            <p>
              The Education (ED) cluster provides a full view of educational
              institutions in the state.
            </p>
          </div>

          <div className="intro-text">
            <p>
              The cluster encompasses elementary schools through universities,
              as well as technical schools and junior colleges.
            </p>

            <p>
              Finally, public agencies that oversee educational programs are
              also included in the cluster.
            </p>
          </div>
        </section>

        <CareerLinkBox image={data.careerImage.sharp.image} caption="" />
      </main>

      <PathwaysIntro
        industry="Education"
        image={data.pathwaysImage.sharp.image}
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: education {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }
    careerImage: file(
      relativePath: { eq: "thisisengineering-raeng-TXxiFuQLBKQ-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(
      relativePath: { eq: "national-cancer-institute-N_aihp118p8-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(
      relativePath: { eq: "taylor-wilcox-4nKOEAQaTgA-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "taylor-wilcox-4nKOEAQaTgA-unsplash.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }

  }
`;

export default Education;
